import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BooleanDto } from 'src/app/_models/boolean-dto';
import { ResetPasswordDto } from 'src/app/_models/reset-password-dto';
import { StringDto } from 'src/app/_models/string-dto';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ResetPasswordService {

  constructor(
    private http: HttpClient
  ) { }

  public getUsernameByToken(token: string, fromCas: string): Observable<StringDto> {
    const endpoint = `users/${token}?fromCas=${fromCas}`;
    const apiURL = `${environment.casRestEndpointRoot}${endpoint}`;
    return this.http.get<StringDto>(apiURL);
  }

  public resetPasswordToUser(token: string, body: ResetPasswordDto, fromCas: string): Observable<ResetPasswordDto> {
    const endpoint = `users/${token}/resetPassword?fromCas=${fromCas}`;
    const apiURL = `${environment.casRestEndpointRoot}${endpoint}`;
    return this.http.post<ResetPasswordDto>(apiURL, body);
  }
}
