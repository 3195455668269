import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { catchError, finalize, map } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';

import { PasswordMatchValidator } from '../validators/password.match.validator';
import { environment } from 'src/environments/environment';
import { ResetPasswordService } from './services/reset-password.service';
import { ResetPasswordDto } from '../_models/reset-password-dto';
import { StringDto } from '../_models/string-dto';
import { AlertService } from '../_services/alert.service';

@Component({
    selector: 'app-reset-password',
    templateUrl: 'reset-password.component.html',
    styleUrls: ['reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
    public resetPassForm: FormGroup;
    public validationType = {};
    public controls = ['ConfirmPassword', 'NewPassword'];
    public passwordHide: boolean;
    public isSubmited: boolean;
    public hasSent: boolean;
    public isSuccess: boolean;
    public confirmPasswordHide: boolean;
    public errorPasswordsMatch = 'Passwords don\'t match';
    public brandingConfigs = {
        title: environment.brandingConfig.appTitle,
        loginImage: environment.brandingConfig.appLoginImage
    };
    public token: string;
    public fromCas: string;
    public isLoadingUser: boolean;

    constructor(private formBuilder: FormBuilder, private router: Router,
                private activatedRoute: ActivatedRoute,
                private resetPasswordService: ResetPasswordService, private alertService: AlertService) { }

    public ngOnInit(): void {
        this.isSubmited = false;
        this.hasSent = false;
        this.isLoadingUser = false;
        const passwordValidators = [Validators.required];
        this.resetPassForm = this.formBuilder.group({
            Username: [{ value: '', disabled: true }],
            NewPassword: [null],
            ConfirmPassword: [null]
        });
        this.validationType = {
            Username: [],
            NewPassword: passwordValidators,
            ConfirmPassword: [PasswordMatchValidator(this.form.NewPassword)].concat(passwordValidators)
        };
        this.passwordHide = true;
        this.confirmPasswordHide = true;

        this.token = this.activatedRoute.snapshot.queryParamMap.get('token');
        this.fromCas = this.activatedRoute.snapshot.queryParamMap.get('fromCas');
        this.getUsernameByToken(this.token, this.fromCas);
    }
    public get form(): any {
        return this.resetPassForm.controls;
    }
    public getUsernameByToken(token: string, fromCas: string): void {
        this.isLoadingUser = true;
        this.resetPasswordService.getUsernameByToken(token, fromCas)
            .pipe(
                map((data: StringDto) => {
                    this.resetPassForm.patchValue({
                        Username: new StringDto(data).value
                    });
                }),
                catchError(err => {
                    this.alertService.error('The access token has expired.');
                    this.cancelClick();
                    return err;
                }),
                finalize(() => {
                    this.isLoadingUser = false;
                })
            ).subscribe();
    }
    public setValidations(input?: string): void {
        if (input) {
            this.form[input].setValidators(this.validationType[input]);
            this.form[input].updateValueAndValidity();
            this.form[input].markAsTouched();
        } else {
            this.controls.forEach((control: string) => {
                this.form[control].setValidators(this.validationType[control]);
                this.form[control].updateValueAndValidity();
                this.form[control].markAsTouched();
            });
        }
        this.resetPassForm.updateValueAndValidity();
    }
    public cancelClick(): void {
        this.hasSent = false;
        this.router.navigateByUrl('/signin');
    }
    public resetPassword(): void {
        this.isSubmited = true;
        this.setValidations();
        if (this.resetPassForm.invalid) {
            this.isSubmited = false;
            return;
        }
        this.toggleFormFields(false);
        this.resetPasswordService.resetPasswordToUser(this.token,
            new ResetPasswordDto({ password: this.form.NewPassword.value }), this.fromCas)
            .pipe(
                map((data: ResetPasswordDto) => {
                    this.isSuccess = true;
                }),
                catchError(err => {
                    this.isSuccess = false;
                    return err;
                }),
                finalize(() => {
                    this.isSubmited = false;
                    this.hasSent = true;
                    this.toggleFormFields(true);
                })
            ).subscribe();
    }

    private toggleFormFields(enable: boolean): void {
        this.controls.forEach((control: string) => {
            if (enable) {
                this.form[control].enable();
            }
            else {
                this.form[control].disable();
            }
        });
    }

}

