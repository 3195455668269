export enum InstanceStatus {
    Ready = 'ready',
    Offline = 'offline',
    Starting = 'starting',
    PendingCreation = 'pending creation',
    PendingUpdate = 'pending update',
    PendingToDelete = 'pending to delete',
    MaintenanceMode = 'maintenance mode',
    Connected = 'connected',
    Online = 'online',
    Stopping = 'stopping'
}
