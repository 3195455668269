<div class="page-wrapper" fxLayout="column" fxLayoutGap="50px" fxLayoutAlign="flex-start center">
  <div fxLayout="row" fxLayoutAlign="center center" class="logo-container">
    <span><img src="{{brandingConfigs?.loginImage ? brandingConfigs?.loginImage : 'assets/images/only_cloud.png'}}"
        alt="logo" class="logo" /></span>
  </div>
  <div fxLayout="row" fxLayoutGap="50px" fxLayoutAlign="center center">
    <mat-card class="login-form" fxFlex="0 0 450px" [fxFlex.xs]="0">
      <div class="title">{{brandingConfigs?.title || 'Cloud VDI Portal'}}</div>

      <div fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="center center">
        <h2 fxFlexOffset="40px"><strong>Reset Password</strong></h2>

        <div *ngIf="isLoadingUser">
          <div fxFlexAlign="center">
            <mat-spinner diameter=40></mat-spinner>
          </div>
        </div>

        <div *ngIf="!isLoadingUser" fxFlex fxLayout="column">
          <div fxFlex fxLayout="row" *ngIf="!hasSent">
            <div fxFlexOffset="30px">
              <p class="text-justify m-r-30">Provide a new password for your Cloud VDI user account. Your new password
                must meet your organization's password policy
              </p>
            </div>
          </div>
          <div fxFlex fxLayout="row" *ngIf="!hasSent">
            <div fxFlex fxLayout="column" fxFlexAlign="center" fxFlexOffset="30px" class="m-r-30">
              <form [formGroup]="resetPassForm" (ngSubmit)="resetPassword()" autocomplete="new-password"
                fxLayout="column">
                <mat-form-field>
                  <input matInput formControlName="Username" placeholder="Username" required
                    (change)="setValidations('Username')" [type]="'text'" />
                  <mat-error *ngIf="form['Username'].touched && form['Username'].invalid">
                    <span *ngIf="form['Username'].errors.required">This field is required.</span>
                  </mat-error>
                </mat-form-field>
                <mat-form-field>
                  <input matInput formControlName="NewPassword" placeholder="New Password" required
                    (change)="setValidations('NewPassword')" (blur)="setValidations('ConfirmPassword')" autocomplete="new-password"
                    [type]="passwordHide ? 'password' : 'text'" />
                  <button mat-icon-button matSuffix type="button" (click)="passwordHide = !passwordHide;"
                    [attr.aria-label]="'Hide password'" [attr.aria-pressed]="passwordHide">
                    <mat-icon>{{passwordHide ? 'visibility_off' : 'visibility'}}</mat-icon>
                  </button>
                  <mat-error *ngIf="form['NewPassword'].touched && form['NewPassword'].invalid">
                    <span *ngIf="form['NewPassword'].errors.required">This field is required.</span>
                    <span *ngIf="form['NewPassword'].errors.pattern">This field is invalid.</span>
                    <span
                      *ngIf="form['NewPassword'].errors.serverError">{{form['NewPassword'].errors.serverError}}</span>
                  </mat-error>
                </mat-form-field>
                <mat-form-field>
                  <input matInput formControlName="ConfirmPassword" placeholder="Confirm New Password" required
                    (change)="setValidations('ConfirmPassword')" (blur)="setValidations('ConfirmPassword')"
                    autocomplete="new-password" [type]="confirmPasswordHide ? 'password' : 'text'" />
                  <button mat-icon-button matSuffix type="button" (click)="confirmPasswordHide = !confirmPasswordHide;"
                    [attr.aria-label]="'Hide password'" [attr.aria-pressed]="passwordHide">
                    <mat-icon>{{confirmPasswordHide ? 'visibility_off' : 'visibility'}}</mat-icon>
                  </button>
                  <mat-error *ngIf="form['ConfirmPassword'].touched && form['ConfirmPassword'].invalid">
                    <span *ngIf="form['ConfirmPassword'].errors.required">This field is required.</span>
                    <span *ngIf="form['ConfirmPassword'].errors.pattern">This field is invalid.</span>
                    <span
                      *ngIf="form['ConfirmPassword'].errors.serverError">{{form['ConfirmPassword'].errors.serverError}}</span>
                    <div *ngIf="!form['ConfirmPassword'].errors.pattern && form['ConfirmPassword'].errors?.mustMatch">
                      {{errorPasswordsMatch}} </div>
                  </mat-error>
                </mat-form-field>
              </form>
            </div>
          </div>
          <div fxLayout="row" fxFlexAlign="start" fxFlexOffset="20px" *ngIf="!hasSent" class="m-b-30">
            <div fxLayout="row" fxFlexOffset="30px" fxLayoutGap="10px">
              <span fxLayout="row" *ngIf="isSubmited" class="m-l-10">
                <mat-spinner class="spinner-pos" diameter=20></mat-spinner>
                <span class="action-label m-t-8"> Setting... </span>
              </span>
              <button mat-flat-button *ngIf="!isSubmited" color="primary" (click)="resetPassword()"
                [disabled]="isSubmited">{{ 'Set Password' | uppercase }}
              </button>
              <button mat-button (click)="cancelClick()" [disabled]="isSubmited">{{'Cancel' | uppercase}}</button>
            </div>
          </div>

          <div fxLayout="column" *ngIf="hasSent">
            <div *ngIf="isSuccess">
              <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
                <div fxLayout="column">
                  <mat-icon class="icon-2x text-success" fxFlexAlign="center">check_circle</mat-icon>
                </div>
                <div fxLayout="row">
                  <p class="text-center m-r-30" fxFlexOffset="30px">
                    Your password was successfully reset!
                  </p>
                </div>
                <div fxLayout="row">
                  <p class="text-center m-r-30" fxFlexOffset="30px">
                    Return to the login screen and log in using your new password.
                  </p>
                </div>
              </div>
            </div>
            <div *ngIf="!isSuccess">
              <div fxLayout="column" fxLayoutGap="15px">
                <div fxLayoutAlign="center center">
                  <div fxLayout="column">
                    <mat-icon class="icon-2x warning-color" fxFlexAlign="center">error_outline</mat-icon>
                  </div>
                </div>
                <div fxLayout="row">
                  <p class="text-center m-r-30" fxFlexOffset="30px">
                    There was a problem resetting your password.
                  </p>
                </div>
                <div fxLayout="row">
                  <p class="text-center m-r-30" fxFlexOffset="30px">
                    Please try again or contact your administrator.
                  </p>
                </div>
              </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="center center" fxFlexOffset="15px">
              <button mat-flat-button color="primary"
                (click)="cancelClick()">{{ 'Return to login screen' | uppercase }}</button>
            </div>
          </div>
        </div>
      </div>
    </mat-card>
  </div>
</div>
