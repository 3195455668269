<div fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="center center">
  <h2 fxFlexOffset="40px"><strong>Reset Password</strong></h2>

  <div fxLayout="row" class="m-r-30" *ngIf="!hasSent">
    <div fxFlexOffset="30px">
      <p class="text-justify">
        If you need to reset your password, provide your username and click <strong>Send Link</strong>. If we have an
        email address for you, you'll receive an email link to reset your password.
      </p>
      <div fxLayout="row">
        <form [formGroup]="sendLinkForm" (ngSubmit)="sendLink()" fxFlex fxLayout fxLayoutAlign="start center">
          <mat-form-field fxFlex class="m-r-10">
            <input matInput formControlName="Username" placeholder="Username" required
              (change)="setValidations('Username')" [type]="'text'" />
            <mat-error *ngIf="form['Username'].touched && form['Username'].invalid">
              <span *ngIf="form['Username'].errors.required">This field is required.</span>
              <span *ngIf="!form['Username'].errors.required && form['Username'].errors.pattern">Invalid format.</span>
            </mat-error>
          </mat-form-field>
        </form>
      </div>
    </div>
  </div>
  <div fxLayout="row" fxFlexAlign="start" *ngIf="!hasSent" class="m-b-30">
    <div fxLayout="row" fxFlexOffset="30px" fxLayoutGap="10px">
      <span fxLayout="row" *ngIf="isSubmited" class="m-l-10">
        <mat-spinner class="spinner-pos" diameter=20></mat-spinner>
        <span class="action-label m-t-8"> Sending... </span>
      </span>
      <button mat-flat-button *ngIf="!isSubmited" color="primary" (click)="sendLink()"
        [disabled]="isSubmited">{{ 'Send Link' | uppercase }}
      </button>
      <button mat-button (click)="cancelClick()" [disabled]="isSubmited">{{'Cancel' | uppercase}}</button>
    </div>
  </div>

  <div *ngIf="hasSent">
    <div *ngIf="isSuccess">
      <div fxLayout="column">
        <div fxLayoutAlign="center center">
          <div fxLayout="column" fxLayoutGap="10px">
            <mat-icon class="icon-2x text-success" fxFlexAlign="center">check_circle</mat-icon>
            <h4><strong>Link sent!</strong></h4>
          </div>
        </div>
        <div fxLayout="row">
          <p class="text-justify m-r-30" fxFlexOffset="30px">You should receive an email message shortly. If you don't
            see
            it, check the spam or junk
            folder in your email
            client.
          </p>
        </div>
      </div>
    </div>
    <div *ngIf="!isSuccess">
      <div fxLayout="column">
        <div fxLayoutAlign="center center">
          <div fxLayout="column" fxLayoutGap="10px">
            <mat-icon class="icon-2x warning-color" fxFlexAlign="center">error_outline</mat-icon>
            <h4><strong>Password reset unavailable</strong></h4>
          </div>
        </div>
        <div fxLayout="row">
          <p class="text-justify m-r-30" fxFlexOffset="30px">
            We couldn't find an email address for that username, or your system administrator has disabled this
            feature.
          </p>
        </div>
        <div fxLayout="row">
          <p class="text-justify m-r-30" fxFlexOffset="30px">
            Please contact your system administrator to request a password reset or to update your email address.
          </p>
        </div>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="center center">
      <a mat-button color="primary" type="button" class="font-bold"
        (click)="cancelClick()">{{'Return to login screen' | uppercase}}</a>
    </div>
  </div>
</div>
