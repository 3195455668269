import { AbstractControl, ValidationErrors } from '@angular/forms';
import * as _ from 'underscore';

export const UserNameValidator = (): ValidationErrors | null => {
    return (control: AbstractControl) => {
        const list = control.value.split('@');
        const isValid = _.contains(control.value, '@') && list.length === 2 && _.all(list, (value) => !_.isEmpty(value));
        return isValid ? null : { pattern: true };
    };
};
