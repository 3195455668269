import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SendResetPasswordLinkActionService {
  public showSendLinkComponent: Observable<boolean>;
  private showSendLinkComponent$: BehaviorSubject<boolean>;

  constructor() {
    this.showSendLinkComponent$ = new BehaviorSubject<boolean>(false);
    this.showSendLinkComponent = this.showSendLinkComponent$.asObservable();
  }

  public setShowSendLinkComponent(showSendLinkComponent: boolean): void {
    this.showSendLinkComponent$.next(showSendLinkComponent);
  }
}
