export enum ResourceStatus {
  PENDING = 'pending',
  AVAILABLE = 'available',
  UNAVAILABLE = 'unavailable',
  MAINTENANCE_MODE = 'maintenance mode',
  CONNECTION_IN_PROCESS = 'connection_in_progress',
  STARTING = 'starting',
  CONNECTED = 'connected',
  OFFLINE = 'offline'
}
