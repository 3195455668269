import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BooleanDto } from 'src/app/_models/boolean-dto';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SendResetPasswordLinkService {

  constructor(
    private http: HttpClient
  ) { }

  public sendResetPasswordLink(username: string): Observable<BooleanDto>{
    const endpoint = `users/${username}/sendResetPasswordEmail`;
    const apiURL = `${environment.casRestEndpointRoot}${endpoint}`;
    return this.http.post<BooleanDto>(apiURL, null);
  }
}
