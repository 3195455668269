<h4 mat-dialog-title> <strong>Change Password</strong></h4>
<div mat-dialog-content>
  <small> Provide a new password for your Cloud VDI user account. Your new password must meet your organization's password policy</small>
  <form [formGroup]="changePasswForm" (ngSubmit)="changePassword()" autocomplete ="new-password">
    <mat-form-field class="m-t-20">
      <input matInput formControlName="NewPassword" placeholder="New Password" required (change)="setValidations('NewPassword')" autocomplete ="new-password"
      [type]="passwordHide ? 'password' : 'text'"/>
      <button mat-icon-button matSuffix type="button" (click)="passwordHide = !passwordHide;" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="passwordHide">
        <mat-icon>{{passwordHide ? 'visibility_off' : 'visibility'}}</mat-icon>
      </button>
      <mat-error *ngIf="form['NewPassword'].touched && form['NewPassword'].invalid">
        <span *ngIf="form['NewPassword'].errors.required">This field is mandatory.</span>
        <span *ngIf="form['NewPassword'].errors.pattern">This field is invalid.</span>
        <span *ngIf="form['NewPassword'].errors.serverError">{{form['NewPassword'].errors.serverError}}</span>
      </mat-error>
    </mat-form-field>
    <mat-form-field>
      <input matInput formControlName="ConfirmPassword" placeholder="Confirm New Password" required (change)="setValidations('ConfirmPassword')" autocomplete ="new-password"
      [type]="confirmPasswordHide ? 'password' : 'text'"/>
      <button mat-icon-button matSuffix type="button" (click)="confirmPasswordHide = !confirmPasswordHide;" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="passwordHide">
        <mat-icon>{{confirmPasswordHide ? 'visibility_off' : 'visibility'}}</mat-icon>
      </button>
      <mat-error *ngIf="form['ConfirmPassword'].touched && form['ConfirmPassword'].invalid">
        <span *ngIf="form['ConfirmPassword'].errors.required">This field is mandatory.</span>
        <span *ngIf="form['ConfirmPassword'].errors.pattern">This field is invalid.</span>
        <span *ngIf="form['ConfirmPassword'].errors.serverError">{{form['ConfirmPassword'].errors.serverError}}</span>
        <div *ngIf="!form['ConfirmPassword'].errors.pattern && form['ConfirmPassword'].errors?.mustMatch"> {{errorPasswordsMatch}} </div>
      </mat-error>
    </mat-form-field>
  </form>
</div>
<div mat-dialog-actions>
  <button mat-flat-button *ngIf="!isSubmited" color="primary" (click)="changePassword()" [disabled]="changePasswForm.touched && changePasswForm.invalid">{{ 'Save' | uppercase }}</button>
  <span fxLayout="row" *ngIf="isSubmited">
    <mat-spinner class="spinner-pos" diameter=20></mat-spinner>
    <span class="action-label"> {{ 'Saving ...' | uppercase}} </span>
  </span>
  <button mat-button (click)="onNoClick()" [disabled]="isSubmited">{{'Cancel' | uppercase}}</button>
</div>
