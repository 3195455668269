import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, Input, OnInit } from '@angular/core';
import { catchError, finalize, map } from 'rxjs/operators';

import { SendResetPasswordLinkActionService } from './services/send-reset-password-link.action.service';
import { SendResetPasswordLinkService } from './services/send-reset-password-link.service';
import { BooleanDto } from '../_models/boolean-dto';
import { UserNameValidator } from '../validators/username.validator';


@Component({
    selector: 'app-send-reset-password-link',
    templateUrl: 'send-reset-password-link.component.html',
    styleUrls: ['send-reset-password-link.component.scss']
})
export class SendResetPasswordLinkComponent implements OnInit {
    @Input()
    public username: string;

    public sendLinkForm: FormGroup;
    public validationType = {};
    public controls = ['Username'];
    public isSubmited: boolean;
    public hasSent: boolean;
    public isSuccess: boolean;

    constructor(private formBuilder: FormBuilder,
                private sendResetPasswordLinkActionService: SendResetPasswordLinkActionService,
                private sendResetPasswordLinkService: SendResetPasswordLinkService) { }

    public ngOnInit(): void {
        this.isSubmited = false;
        this.hasSent = false;
        this.sendLinkForm = this.formBuilder.group({
            Username: [{ value: this.username, disabled: false }],
        });
        this.validationType = {
            Username: [Validators.required, UserNameValidator()]
        };
    }
    public get form(): any {
        return this.sendLinkForm.controls;
    }
    public setValidations(input?: string): void {
        if (input) {
            this.form[input].setValidators(this.validationType[input]);
            this.form[input].updateValueAndValidity();
            this.form[input].markAsTouched();
        } else {
            this.controls.forEach((control: string) => {
                this.form[control].setValidators(this.validationType[control]);
                this.form[control].updateValueAndValidity();
                this.form[control].markAsTouched();
            });
        }
        this.sendLinkForm.updateValueAndValidity();
    }
    public cancelClick(): void {
        this.hasSent = false;
        this.sendResetPasswordLinkActionService.setShowSendLinkComponent(false);
    }
    public sendLink(): void {
        this.isSubmited = true;
        this.setValidations();
        if (this.sendLinkForm.invalid) {
            this.isSubmited = false;
            return;
        }
        this.sendResetPasswordLinkService.sendResetPasswordLink(this.form.Username.value)
            .pipe(
                map((data: BooleanDto) => {
                    this.isSuccess = new BooleanDto(data).value;
                }),
                catchError(err => {
                    this.isSuccess = false;
                    return err;
                }),
                finalize(() => {
                    this.isSubmited = false;
                    this.hasSent = true;
                })
            ).subscribe();
    }

}
